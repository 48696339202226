.color-red {
  color: red;
}

.div-bg-col {
  /* background-color: rgba(83, 65, 250, 0.2); */
  border-radius: 5px;
}

.text-custom {
  font-size: 2em;
}

.color-yellow {
  color: #e9c46a;
}

.color-0e487e {
  color: #0e487e;
}

.publication {
  min-height: 70vh;
}
