.about-section{
    color: #fff;
    padding-top: 100px;
}

.topics-section{
    color: #fff;
    padding-top: 100px;
}

.speakers-section{
    color: #fff;
}

.ruler {
    width: 300px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.topic-card{
    /* background:#fff; */
    max-width: 300px;
    min-width: 300px;
    /* background-color: rgba(41, 41, 41, 0.9); */
    background-color: rgba(255, 255, 255, 0.1);
    margin: 1em;
    /* color: #fff; */
    /* background: rgba(20, 33, 61, 0.2); */
    /* background: rgba(255, 255, 255, 0.1); */
    /* margin: 10px auto; */
    /* padding: 20px; */
    color: #fff;
    /* border: 1px solid rgba(000, 000, 000, 0.2); */
    border-radius: 10px;
    padding-bottom: 1em;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    /* border-radius: 10px; */
    /* box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.1); */
    /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
    font-size: 2vh;
    font-weight: 500;
}

.topic-item{
    /* background: rgba(255, 255, 255, 1); */
    /* border: 1px solid #007bff; */
    /* border-radius: 50px; */
    padding: 1em;
    margin: 1em;
}

.speaker-card{
    background:#007bff;
    /* background: #14213d; */
    min-height: 350px;
    color: #fff;
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    /* box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25); */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    font-size: 1.3rem;
    font-weight: 200;
}

.speaker-desc {
    opacity: 0.7;
    font-size: 0.8rem;
    padding: 5px 5px;
}

.speaker-desc-front{
    opacity: 1;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
}

.no-margin{
    margin: 0 0;
}

.footer-section{
    background: #14213d;
    color: #fff;
}

.footer-list{
    text-decoration: none;
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
}

.footer-text{
    margin-bottom: 0;
    padding-bottom: 5px;
}

.speaker-img{
    /* height: 250px; */
    height: 70%;
    width: 100%;
}

.flip-card{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.speaker-card:hover .flip-card {
    transform: rotateY(180deg);
}



.card-front, .card-back {
    padding-top: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

.card-front {
  color: black;
}

.card-back {
  color: white;
  transform: rotateY(180deg);
}

@media (max-width: 768px) {

    .topics-section{
        padding-top: 30px;
    }

    .topic-card{
        max-width: 80%;
    }

    .topic-item{
        padding-bottom: 0px;
    }

    .speaker-card{
        min-height: 500px;
        max-width: 80%;
        margin: 30px auto;
    }

    .speaker-card:last-child{
        margin-bottom: 0;
    }
    .footer-section{
        margin-top: 100px;
    }

    .footer-list{
        align-items: flex-start;
    }

    .speaker-img{
        height: auto;
        max-height: 450px;
    }
    .footer-list{
        flex-direction: column;
    }
}

@media (max-height: 667px) {
    .speaker-card{
        min-height: 450px;
    }
}

.staff-img{
    border-radius: 50% !important;
}

.staff-name {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 12px 0 4px;
}

@media screen and (min-width: 567px){
    .staff-name {
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        padding: 12px 0 4px;
    }
}

.staff-designation, .staff-qualification {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    display: block;
    font-weight: 400;
    opacity: 0.82;
}

@media screen and (min-width: 567px){
    .staff-designation, .staff-qualification {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        display: block;
        font-weight: 400;
        opacity: 0.82;
    }
}

.ruler_custom {
    width: 50px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.row_center{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.row_left{
    justify-content: start;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}