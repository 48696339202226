@import url(https://fonts.googleapis.com/css2?family=Cabin);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
  font-family: "Cabin", sans-serif;
}

h1 {
  color: #e9c46a;
  text-shadow: 1px 2px 3px rgba(000, 000, 000, 0.5);
}

h4 {
  color: #e9c46a;
}

body {
  background: #f2f2f2;
  /* background: linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%); */
}

.wave {
  position: absolute;
  z-index: 0;
  bottom: 0;
}

.bd-masthead {
  position: relative;
  padding: 50px 15px;
  /* background-image: url('./assets/wave.svg'); */
  /* background-repeat: no-repeat; */
  /* background-position: bottom; */
  /* background-image: url('./assets/wave.svg'); */
  /* background-repeat: no-repeat; */
  /* background-position: bottom; */
  /* background-position-y: bottom; */
  /* background: linear-gradient(to right bottom,#007bff 20%,rgba(20, 15, 71, 0.26) 50%); */
  /* background: linear-gradient(to right, rgb(41, 128, 185), rgb(109, 213, 250), rgb(255, 255, 255)); */
  /* background: linear-gradient(to right, rgb(127, 127, 213), rgb(134, 168, 231), rgb(145, 234, 228)); */
  /* background: linear-gradient(to right, rgb(168, 192, 255), rgb(63, 43, 150)); */
  /* background: linear-gradient(to right, rgb(131, 96, 195), rgb(46, 191, 145)); */
  /* background: linear-gradient(to right, rgb(0, 90, 167), rgb(255, 253, 228)); */
  color: #fff;
  z-index: 90;
}

.lead-img {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.4));
}

.middle-align {
  margin-top: auto;
}

.btn-border {
  /* border: 1px solid rgba(255,255,255, 0.1); */
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.lead-text {
  font-weight: 500;
  font-size: 5rem;
  /* margin-top: 80px; */
  /* filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.4)); */
  text-shadow: 1px 2px 3px rgba(000, 000, 000, 0.5);
}

.desc {
  font-size: 2vh;
}
@media (min-width: 760px) {
  .desc {
    font-size: 4vh;
  }
}

.spons-text {
  font-size: 3.5vh;
  /* margin-top: 80px; */
}

.details-head {
  font-size: 0.75rem;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.8);
}

.details-text {
  color: #fff;
  text-shadow: 1px 2px 3px rgba(000, 000, 000, 0.3);
}

@media (max-width: 760px) {
  .bd-masthead {
    padding: 0 15px 25px 15px;
    margin-top: 15px;
  }

  .mobileHide {
    display: none;
  }

  .mastHead {
    font-size: 2rem;
  }

  .mastPara {
    font-size: medium;
  }

  .lead-text {
    font-size: 6vh;
  }

  .spons-text {
    margin-top: 10px;
  }

  .spons-text {
    font-size: 1rem;
  }

  .details-text {
    font-size: 1rem;
  }

  .sponsor {
    -webkit-justify-content: center;
            justify-content: center;
  }
}

@media (max-height: 667px) {
  .bd-masthead {
    margin-top: 0;
    padding: 0 15px;
  }

  .lead-text {
    font-size: 5vh;
  }

  .flex-xs-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .btn-border {
    width: 50%;
    font-size: 1rem;
    margin-right: 5px;
  }

  .details-block {
    margin-top: 0rem !important;
  }

  .details-text {
    font-size: 2vh;
  }
}

.fullBackground img {
  position: absolute;
  z-index: 1;
  top: 0px;
  height: 100vh;
  opacity: 0.08;
  width: 95vw;
}

.about-section{
    color: #fff;
    padding-top: 100px;
}

.topics-section{
    color: #fff;
    padding-top: 100px;
}

.speakers-section{
    color: #fff;
}

.ruler {
    width: 300px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.topic-card{
    /* background:#fff; */
    max-width: 300px;
    min-width: 300px;
    /* background-color: rgba(41, 41, 41, 0.9); */
    background-color: rgba(255, 255, 255, 0.1);
    margin: 1em;
    /* color: #fff; */
    /* background: rgba(20, 33, 61, 0.2); */
    /* background: rgba(255, 255, 255, 0.1); */
    /* margin: 10px auto; */
    /* padding: 20px; */
    color: #fff;
    /* border: 1px solid rgba(000, 000, 000, 0.2); */
    border-radius: 10px;
    padding-bottom: 1em;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    /* border-radius: 10px; */
    /* box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.1); */
    /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
    font-size: 2vh;
    font-weight: 500;
}

.topic-item{
    /* background: rgba(255, 255, 255, 1); */
    /* border: 1px solid #007bff; */
    /* border-radius: 50px; */
    padding: 1em;
    margin: 1em;
}

.speaker-card{
    background:#007bff;
    /* background: #14213d; */
    min-height: 350px;
    color: #fff;
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    /* box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25); */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    font-size: 1.3rem;
    font-weight: 200;
}

.speaker-desc {
    opacity: 0.7;
    font-size: 0.8rem;
    padding: 5px 5px;
}

.speaker-desc-front{
    opacity: 1;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
}

.no-margin{
    margin: 0 0;
}

.footer-section{
    background: #14213d;
    color: #fff;
}

.footer-list{
    text-decoration: none;
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 10px;
}

.footer-text{
    margin-bottom: 0;
    padding-bottom: 5px;
}

.speaker-img{
    /* height: 250px; */
    height: 70%;
    width: 100%;
}

.flip-card{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.speaker-card:hover .flip-card {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}



.card-front, .card-back {
    padding-top: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

.card-front {
  color: black;
}

.card-back {
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

@media (max-width: 768px) {

    .topics-section{
        padding-top: 30px;
    }

    .topic-card{
        max-width: 80%;
    }

    .topic-item{
        padding-bottom: 0px;
    }

    .speaker-card{
        min-height: 500px;
        max-width: 80%;
        margin: 30px auto;
    }

    .speaker-card:last-child{
        margin-bottom: 0;
    }
    .footer-section{
        margin-top: 100px;
    }

    .footer-list{
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }

    .speaker-img{
        height: auto;
        max-height: 450px;
    }
    .footer-list{
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}

@media (max-height: 667px) {
    .speaker-card{
        min-height: 450px;
    }
}

.staff-img{
    border-radius: 50% !important;
}

.staff-name {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 12px 0 4px;
}

@media screen and (min-width: 567px){
    .staff-name {
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        padding: 12px 0 4px;
    }
}

.staff-designation, .staff-qualification {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    display: block;
    font-weight: 400;
    opacity: 0.82;
}

@media screen and (min-width: 567px){
    .staff-designation, .staff-qualification {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        display: block;
        font-weight: 400;
        opacity: 0.82;
    }
}

.ruler_custom {
    width: 50px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.row_center{
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.row_left{
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
:root {
  /* --card-bg-color: #007bff; */
  --card-bg-color: #f1faee;
  --text-color: #000;
}

.profile-card-container {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  /* padding: 30px; */
}

.profile-card-container .profile-card {
  position: relative;
  max-width: 250px;
  height: auto;
  border-radius: 5px;
  background: #f1faee;
  background: var(--card-bg-color);
  color: #000;
  color: var(--text-color);
  margin: 30px 10px;
  padding: 20px 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.profile-card-container .profile-card:hover {
  height: auto;
  /* transition: all 0.3s ease-in-out; */
}

.profileImage {
  position: relative;
  width: 220px;
  height: 220px;
  top: -60px;
  left: 0px;
  /* box-shadow: 0 5px 20px rgba(0,0,0,0.2); */
}

.profileHeadText {
  font-size: 1.3em;
  color: #0069d9;
}

.profileSmallDescription {
  opacity: 0.8;
  font-size: 1em;
}

.profileImage img {
  max-width: 100%;
  border-radius: 4px;
}

.profileContent {
  display: none;
  visibility: none;
  position: relative;
  margin-top: -140px;
  /* margin-top: auto; */
  padding: 10px 15px;
  text-align: center;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.profileHead {
  text-align: center;
  margin: 0.5em 0 1em 0;
  padding-bottom: 10px;
}

.profile-card:hover .profileContent {
  visibility: visible;
  display: block;
  opacity: 1;
  margin-top: -20px;
  transition: 0.3s ease-in-out;
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 360px) {
  .profileImage img {
    max-width: 95%;
  }
}

.btn-alt {
  background: rgba(116, 0, 184, 1);
  /* padding: 10px; */
  /* border-radius: 7px; */
  /* box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.1); */
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  transition: ease-in-out all 0.1s;
  /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;; */
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-alt:hover {
  color: #fff;
  background: rgba(116, 0, 184, 0.8);
  /* box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.3), 0 5px 10px 0 rgba(0, 0, 0, 0.3); */
  /* box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.1); */
  text-decoration: none;
}

@media (max-height: 667px) {
  .btn-alt {
    font-size: 1rem;
  }
}

.custom-text-col {
  color: #e9c46a;
}

.host-section img {
  -webkit-filter: drop-shadow(0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.7));
}

.fullBanner {
  position: relative;
  display: show;
  border: 1px solid blue;
  padding: 15px;
  width: 100%;
  margin-bottom: 2em;
  text-align: center;
  background-color: royalblue;
}

.closeBtn {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
}

.hide {
  display: none;
}

.sponsored-img {
  background: white;
  border-radius: 3em;
}

.card-wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
}

  .card-content {
    max-width: 1024px;
    width: 100%;
    padding: 0 4%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  
  @media screen and (max-width: 767px) {
    .card-content {
      -webkit-flex-direction: column;
              flex-direction: column;
    }
  }

  .svg-icon{
    -webkit-filter: invert(99%) sepia(99%) saturate(2%) hue-rotate(173deg) brightness(103%) contrast(100%);
            filter: invert(99%) sepia(99%) saturate(2%) hue-rotate(173deg) brightness(103%) contrast(100%);
    transition: all 0.3s ease;
  }

  .card {
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    height: 250px;
    /* background-color: #292929; */
    background-color: rgba(255, 255, 255, 0.1);
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    cursor: pointer;
    transition: all 0.3s ease;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none;
  }
  
  .icon {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    /* background: linear-gradient(90deg, #ff7e7e 0%, #ff4848 40%, rgba(0, 0, 0, 0.28) 60%); */
    background: linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    border-radius: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    transition: all 0.8s ease;
    background-position: 0px;
    background-size: 200px;
  }
  
  .card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 30px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
  
  .card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease;
  }
  
  .card:hover {
    height: 270px;
  }
  
  .card:hover .info {
    height: 90%;
  }
  
  .card:hover .text {
    transition: all 0.3s ease;
    opacity: 0.7;
    max-height: 40px;
  }
  
  .card:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
  }
  
  .card:hover .icon i {
    /* background: linear-gradient(90deg, #ff7e7e, #ff4848); */
    background: linear-gradient(90deg, #dc2f02, #14213d);   
    opacity: 1;
    transition: all 0.3s ease;
  }

   .card:hover .svg-icon{
    -webkit-filter: invert(9%) sepia(67%) saturate(1066%) hue-rotate(192deg) brightness(94%) contrast(93%);
            filter: invert(9%) sepia(67%) saturate(1066%) hue-rotate(192deg) brightness(94%) contrast(93%);
    transition: all 0.3s ease;
  }
  

  @media screen and (max-width: 767px) {
    .card{
        font-size: 12px;
    }

    .card-content{
      -webkit-align-items: center;
              align-items: center;
    }
  }

.carousel-caption {
    background: rgba(000, 000, 000, 0.5);
}

@media (max-width: 768px){
    .carousel-caption {
        background: rgba(000, 000, 000, 0.5);
    }
}
.uList {
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  list-style: none;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  /* background: #111; */
  /* background: linear-gradient(to right, rgb(131, 96, 195), rgb(46, 191, 145)); */
  /* background: linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%); */
  color: #fff;
  padding: 20px;
  margin-bottom: 0;
}

.logo {
  margin-right: auto;
  margin-left: 4%;
  z-index: 99;
}

#navLink {
  display: -webkit-flex;
  display: flex;
  padding-top: 12px;
  -webkit-align-items: baseline;
          align-items: baseline;
  z-index: 99;
}
#navLink li {
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  z-index: 99;
}

#navLink li a {
  color: #fff;
}

#navLink li a:hover {
  color: #e9c46a;
}

.alt-colr:hover {
  color: #fff;
}

a:hover {
  text-decoration: none;
}

@media (max-width: 1024px) {
  #navLink {
    display: none;
    padding-top: 0px;
    z-index: 99;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
  }

  #navLink.responsive {
    display: block;
  }

  .uList {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0;
  }
  .logo {
    margin-left: 3%;
    padding: 5px 0;
  }

  #navLink li {
    border-bottom: 1px solid rgba(000, 000, 000, 0.2);
    padding-top: 10px;
    margin: 5px;
    margin-left: 4%;
    padding-bottom: 10px;
  }

  #navLink li:first-child {
    padding-top: 10px;
  }

  #navLink li:last-child {
    border-bottom: none;
  }

  #nav-icon {
    float: right;
    display: block;
    width: 60px;
    height: 25px;
    position: absolute;
    top: 2.4%;
    right: 0%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    float: right;
    display: block;
    position: absolute;
    height: 2px;
    width: 65%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  #nav-icon span:nth-child(1) {
    top: 0px;
  }

  #nav-icon span:nth-child(2),
  #nav-icon span:nth-child(3) {
    top: 10px;
  }

  #nav-icon span:nth-child(4) {
    top: 20px;
  }

  #nav-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

#alt-colr:hover {
  color: #fff;
}

.root-file {
  background-color: #010938;
  /* background-color: #000C24; */
  /* background-color: #a2d9ff; */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23D18'/%3E%3Cstop offset='1' stop-color='%23330000'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%23FA3' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23FA3' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.4'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E"); */
  /* background-image: url("./assets/Rose-Petals.svg"); */
  /* background-image: url("./assets/bg1.jpg"); */
  /* background-image: url("./assets/wave1.svg"); */
  /* background-attachment: fixed;
    background-size: cover; */
}

body {
    background-color: #330000;
    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23D18'/%3E%3Cstop offset='1' stop-color='%23330000'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%23FA3' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23FA3' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.4'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E"); */
    background-image: url(../../static/media/Rose-Petals.1911e778.svg);
    background-attachment: fixed;
    background-size: cover;
}

.publication-list{
    color: #fff;
}

.prev-custom-font{
    font-size: 2em;
}

@media(max-width: 768px){
    .prev-custom-font{
        font-size: 1.1em;
    }
}
.pagination{
    margin-left: 1.75em;
}
.color-red {
  color: red;
}

.div-bg-col {
  /* background-color: rgba(83, 65, 250, 0.2); */
  border-radius: 5px;
}

.text-custom {
  font-size: 2em;
}

.color-yellow {
  color: #e9c46a;
}

.color-0e487e {
  color: #0e487e;
}

.publication {
  min-height: 70vh;
}

.reg-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-content: stretch;
          align-content: stretch;
  max-width: 900px;
  margin: 40px auto;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding-bottom: 5%;
}

@media all and (max-width: 900px) {
  .reg-container {
    width: 90%;
  }
}
@media all and (max-width: 725px) {
  .reg-container {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .reg-card {
    width: 100%;
  }
}
.reg-card {
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-right: 20px;
  margin-bottom: 20px;
  /* background: #fff; */
  background: var(--card-bg-color);
  border: #ecedf0 4px solid;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  position: relative;
  left: -1500px;
  -webkit-animation: 1.5s cubic-bezier(0.53, 0.2, 0.54, 1.33) 0s 1 slidein;
  animation: 1.5s cubic-bezier(0.53, 0.2, 0.54, 1.33) 0s 1 slidein;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.reg-card:last-of-type {
  margin-right: 0;
}
.reg-card:nth-of-type(2) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 1.75s;
  animation-duration: 1.75s;
}
.reg-card:nth-of-type(3) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes slidein {
  from {
    left: -1500px;
  }
  to {
    left: 0;
  }
}

@keyframes slidein {
  from {
    left: -1500px;
  }
  to {
    left: 0;
  }
}

.reg-card ul {
  list-style-type: none;
  padding: 3em 0 15px 0;
}
.reg-card ul li {
  font-size: 1.5rem;
  padding-bottom: 10px;
}
.reg-card ul li:first-of-type {
  padding-top: 10px;
}

.reg-price {
  width: 100%;
  /* background: #ecedf0; */
  /* background: lightblue; */
  background: rgba(116, 0, 184, 0.1);
}

.reg-container h3 {
  font-size: 3em;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}
.reg-container h3 span {
  font-weight: 400;
  font-size: 0.4em;
}

.reg-container h1 {
  font-size: 1em;
  font-weight: 500;
}

.reg-container h2 {
  font-size: 0.6em;
  font-weight: 400;
  padding: 3px 5px 15px 5px;
}

.reg-rupee {
  font-size: 1em !important;
}

.registerForm {
  position: absolute;
  z-index: 99;
  background-color: #fff;
  height: 60%;
  width: 70%;
  -webkit-transform: translate(0%, -3%);
          transform: translate(0%, -3%);
}

@media (max-width: 768px) {
  .registerForm {
    height: 110%;
    top: 30px;
    width: 90%;
    -webkit-transform: translate(0, 15%);
            transform: translate(0, 15%);
  }
}

