.btn-alt {
  background: rgba(116, 0, 184, 1);
  /* padding: 10px; */
  /* border-radius: 7px; */
  /* box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.1); */
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  transition: ease-in-out all 0.1s;
  /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;; */
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-alt:hover {
  color: #fff;
  background: rgba(116, 0, 184, 0.8);
  /* box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.3), 0 5px 10px 0 rgba(0, 0, 0, 0.3); */
  /* box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.1); */
  text-decoration: none;
}

@media (max-height: 667px) {
  .btn-alt {
    font-size: 1rem;
  }
}

.custom-text-col {
  color: #e9c46a;
}
