.reg-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  align-content: stretch;
  max-width: 900px;
  margin: 40px auto;
  justify-content: space-around;
  padding-bottom: 5%;
}

@media all and (max-width: 900px) {
  .reg-container {
    width: 90%;
  }
}
@media all and (max-width: 725px) {
  .reg-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .reg-card {
    width: 100%;
  }
}
.reg-card {
  border-radius: 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1;
  margin-right: 20px;
  margin-bottom: 20px;
  /* background: #fff; */
  background: var(--card-bg-color);
  border: #ecedf0 4px solid;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  position: relative;
  left: -1500px;
  -webkit-animation: 1.5s cubic-bezier(0.53, 0.2, 0.54, 1.33) 0s 1 slidein;
  animation: 1.5s cubic-bezier(0.53, 0.2, 0.54, 1.33) 0s 1 slidein;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.reg-card:last-of-type {
  margin-right: 0;
}
.reg-card:nth-of-type(2) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 1.75s;
  animation-duration: 1.75s;
}
.reg-card:nth-of-type(3) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes slidein {
  from {
    left: -1500px;
  }
  to {
    left: 0;
  }
}

@keyframes slidein {
  from {
    left: -1500px;
  }
  to {
    left: 0;
  }
}

.reg-card ul {
  list-style-type: none;
  padding: 3em 0 15px 0;
}
.reg-card ul li {
  font-size: 1.5rem;
  padding-bottom: 10px;
}
.reg-card ul li:first-of-type {
  padding-top: 10px;
}

.reg-price {
  width: 100%;
  /* background: #ecedf0; */
  /* background: lightblue; */
  background: rgba(116, 0, 184, 0.1);
}

.reg-container h3 {
  font-size: 3em;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}
.reg-container h3 span {
  font-weight: 400;
  font-size: 0.4em;
}

.reg-container h1 {
  font-size: 1em;
  font-weight: 500;
}

.reg-container h2 {
  font-size: 0.6em;
  font-weight: 400;
  padding: 3px 5px 15px 5px;
}

.reg-rupee {
  font-size: 1em !important;
}

.registerForm {
  position: absolute;
  z-index: 99;
  background-color: #fff;
  height: 60%;
  width: 70%;
  transform: translate(0%, -3%);
}

@media (max-width: 768px) {
  .registerForm {
    height: 110%;
    top: 30px;
    width: 90%;
    transform: translate(0, 15%);
  }
}
