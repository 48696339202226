.uList {
  z-index: 99;
  display: flex;
  list-style: none;
  justify-content: flex-end;
  /* background: #111; */
  /* background: linear-gradient(to right, rgb(131, 96, 195), rgb(46, 191, 145)); */
  /* background: linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%); */
  color: #fff;
  padding: 20px;
  margin-bottom: 0;
}

.logo {
  margin-right: auto;
  margin-left: 4%;
  z-index: 99;
}

#navLink {
  display: flex;
  padding-top: 12px;
  align-items: baseline;
  z-index: 99;
}
#navLink li {
  flex-direction: row;
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  z-index: 99;
}

#navLink li a {
  color: #fff;
}

#navLink li a:hover {
  color: #e9c46a;
}

.alt-colr:hover {
  color: #fff;
}

a:hover {
  text-decoration: none;
}

@media (max-width: 1024px) {
  #navLink {
    display: none;
    padding-top: 0px;
    z-index: 99;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
  }

  #navLink.responsive {
    display: block;
  }

  .uList {
    justify-content: flex-start;
    flex-direction: column;
    padding: 0;
  }
  .logo {
    margin-left: 3%;
    padding: 5px 0;
  }

  #navLink li {
    border-bottom: 1px solid rgba(000, 000, 000, 0.2);
    padding-top: 10px;
    margin: 5px;
    margin-left: 4%;
    padding-bottom: 10px;
  }

  #navLink li:first-child {
    padding-top: 10px;
  }

  #navLink li:last-child {
    border-bottom: none;
  }

  #nav-icon {
    float: right;
    display: block;
    width: 60px;
    height: 25px;
    position: absolute;
    top: 2.4%;
    right: 0%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    float: right;
    display: block;
    position: absolute;
    height: 2px;
    width: 65%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon span:nth-child(1) {
    top: 0px;
  }

  #nav-icon span:nth-child(2),
  #nav-icon span:nth-child(3) {
    top: 10px;
  }

  #nav-icon span:nth-child(4) {
    top: 20px;
  }

  #nav-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

#alt-colr:hover {
  color: #fff;
}
