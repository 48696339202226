.card-wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
}

  .card-content {
    max-width: 1024px;
    width: 100%;
    padding: 0 4%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  @media screen and (max-width: 767px) {
    .card-content {
      flex-direction: column;
    }
  }

  .svg-icon{
    filter: invert(99%) sepia(99%) saturate(2%) hue-rotate(173deg) brightness(103%) contrast(100%);
    transition: all 0.3s ease;
  }

  .card {
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    height: 250px;
    /* background-color: #292929; */
    background-color: rgba(255, 255, 255, 0.1);
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.3s ease;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none;
  }
  
  .icon {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    /* background: linear-gradient(90deg, #ff7e7e 0%, #ff4848 40%, rgba(0, 0, 0, 0.28) 60%); */
    background: linear-gradient(90deg, hsla(217, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transition: all 0.8s ease;
    background-position: 0px;
    background-size: 200px;
  }
  
  .card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 30px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
  
  .card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease;
  }
  
  .card:hover {
    height: 270px;
  }
  
  .card:hover .info {
    height: 90%;
  }
  
  .card:hover .text {
    transition: all 0.3s ease;
    opacity: 0.7;
    max-height: 40px;
  }
  
  .card:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
  }
  
  .card:hover .icon i {
    /* background: linear-gradient(90deg, #ff7e7e, #ff4848); */
    background: linear-gradient(90deg, #dc2f02, #14213d);   
    opacity: 1;
    transition: all 0.3s ease;
  }

   .card:hover .svg-icon{
    filter: invert(9%) sepia(67%) saturate(1066%) hue-rotate(192deg) brightness(94%) contrast(93%);
    transition: all 0.3s ease;
  }
  

  @media screen and (max-width: 767px) {
    .card{
        font-size: 12px;
    }

    .card-content{
      align-items: center;
    }
  }
